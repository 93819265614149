
import { defineComponent, ref } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import AuthWhiteBlock from '@/components/pages/auth/AuthWhiteBlock.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    AuthWhiteBlock,
    PageAuth,
  },
  setup() {
    const code = ref<string>('')
    return {
      code,
    }
  },
})
